'use client';

import { getAccessToken, setCookie } from '@ahm/api-wrappers-core';
import { useSearchParams } from 'next/navigation';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { toast } from 'sonner';
import { useFBLoginMerchant } from '@/api/use-facebook-login';
import { useGetUserInfo } from '@/api/use-get-user-info';
import { usePostFacebookPage } from '@/api/use-upset-page';
import { publicEnv } from '@/config/public-env.config.mjs';

export type FBuser = {
  _id: string;
  email: string;
  status: string;
  name: string;
  facebook_id: string;
  facebook_token: string;
  create_time: number;
};

export interface PageInfo {
  data: PageData[];
  paging: Paging;
}

export interface PageData {
  map: any;
  access_token: string;
  category: string;
  category_list: CategoryList[];
  name: string;
  id: string;
  tasks: string[];
}

export interface CategoryList {
  id: string;
  name: string;
}

export interface Paging {
  cursors: Cursors;
}

export interface Cursors {
  before: string;
  after: string;
}

interface AuthState {
  accessToken: string | null;
  user: FBuser | null;
  status: 'authorized' | 'unauthorized' | 'loading';
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getAccessToken()
  );

  const [user, setUser] = useState<FBuser | null>(null);
  const [status, setStatus] = useState<AuthState['status']>('loading');
  const searchParams = useSearchParams();
  const sessionToken = searchParams.get('session_token');
  const hasLoggedIn = useRef(false);

  const { data: userData } = useGetUserInfo({
    queryKey: ['userInfor', accessToken],
    enabled: !!accessToken,
  });

  const { mutate: upsetPage } = usePostFacebookPage({
    onError: () => {
      toast.error('Lỗi khi xử lý dữ liệu trang, vui lòng thử lại sau');
    },
  });

  const handleUpsetPages = useCallback(
    async (pages: PageData[]) => {
      const upsetPromises = pages.map((page) => {
        const upsetPageData = {
          page_id: page.id,
          page_access_token: page.access_token,
          page_name: page.name,
          // integrated_user_id: userId,
        };
        return upsetPage(upsetPageData);
      });

      try {
        const results = await Promise.allSettled(upsetPromises);
        results.forEach((result, index) => {
          if (result.status === 'rejected') {
            toast.error(
              `Lỗi cập nhật trang ${pages[index].name}: ${result.reason}`
            );
          }
        });
        setStatus('authorized');
      } catch (error) {
        toast.error('Đã xảy ra lỗi khi cập nhật trang. Vui lòng thử lại sau.');
        setStatus('unauthorized');
      }
    },
    [upsetPage]
  );

  const { mutate: mutateFBLogin } = useFBLoginMerchant({
    onSuccess(res) {
      setCookie('token', res.access_token);
      setAccessToken(res.access_token);
      toast.success('Đăng nhập thành công');

      fetch(
        `https://graph.facebook.com/v20.0/me/accounts?access_token=${sessionToken}`,
        {
          method: 'GET',
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          void handleUpsetPages(data.data);
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('Lỗi khi lấy thông tin trang Facebook');
          setStatus('unauthorized');
        });

      clearDataFromUrl(['session_token']);
    },
    onError() {
      toast.error('Không thể đăng nhập ở thời điểm này, vui lòng thử lại sau.');
      setStatus('unauthorized');
      clearDataFromUrl(['session_token']);
    },
  });

  useEffect(() => {
    if (userData) {
      setStatus('authorized');
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    }
  }, [userData]);

  function clearDataFromUrl(fields: string[]) {
    try {
      const url = new URL(window.location.href);
      fields.forEach((field) => url.searchParams.delete(field));
      window.history.replaceState({}, '', url.toString());
    } catch (error) {
      console.error('Failed to clear error code and message from url', error);
    }
  }

  const login = useCallback(() => {
    const scopes = [
      'email',
      'pages_messaging',
      'pages_show_list',
      'pages_read_user_content',
      'pages_manage_ads',
      'pages_manage_engagement',
    ];

    const fbLoginUrl = `https://www.facebook.com/${publicEnv.NEXT_PUBLIC_FACEBOOK_API_VERSION}/dialog/oauth?client_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}/api/auth/callback/facebook&scope=${scopes.join(',')}&response_type=code&auth_type=rerequest&state={}`;
    window.open(fbLoginUrl, '_self');
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('page');
    localStorage.removeItem('isupset');
    setCookie('token', '', { expires: new Date(0) });
    setAccessToken(null);
    setUser(null);
    setStatus('unauthorized');
    clearDataFromUrl(['session_token']);
  }, []);

  useEffect(() => {
    if (sessionToken && !hasLoggedIn.current) {
      mutateFBLogin({
        facebook_token: sessionToken,
      });
      hasLoggedIn.current = true;
    } else if (!sessionToken) {
      setStatus('unauthorized');
    }
  }, [sessionToken, mutateFBLogin]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        status,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
