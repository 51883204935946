import {
  goshopApiUrl,
  publicHttpClient,
  type AxiosRequestConfig,
} from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export interface Promo1 {
  description: string;
  promo_code: string;
}

export interface OpenHour {
  start_time: number;
  end_time: number;
}

export interface Store {
  page_id: number;
  city_id: string;
  name: string;
  ai_tags: string[];
  parent: string;
  is_closed: boolean;
  auto_confirm: boolean;
  auto_payout: boolean;
  image_url: string;
  mobile: string;
  payment_account: string;
  management_account: string;
  payment_methods: [string];
  distance: number;
  parking_fee: number;
  open_dates: any[];
  available_to: any[];
  address: string;
  promo1: Promo1;
  description: string;
  create_at: number;
  update_at: number;
  open_hours: Record<string, OpenHour[]>;
  location: Location;
  admin_note: string;
  type: string;
  short_promo: string;
  _id: number;
  tags: any[];
  verified: boolean;
  is_active: boolean;
  menu_link: string[];
  promotion_info: string;
  fb_fanpage?: string;
}

export interface NearbyStoresResponse {
  stores: Store[];
}
export interface GetNearbyResquestParams {
  lat: number;
  lng: number;
  // city_id: string;
  skip: number;
}
export const getNearbyStoresFn = async (
  params: GetNearbyResquestParams,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<NearbyStoresResponse>(
    goshopApiUrl + apiRoutes.ahafood.store.get_nearby,
    {
      params,
      ...config,
    }
  );

  return response?.data;
};
