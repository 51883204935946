export const apiRoutes = {
  ahafood: {
    store: {
      get_category: `/api/v3/public/food-order/category`,
      get_by_name: `/v2/store/search`,
      get_by_city: `/api/v3/public/food-order/store`,
      get_nearby: `/v2/store/nearby`,
      get_by_category: '/api/v3/public/food-order/store/category',
    },
    update_page: (pageId: string) =>
      `/api/v3/private/food-order/page/${pageId}`,
    get_page: `/api/v3/private/food-order/page`,
    get_info: 'api/v3/private/food-order/self',
    fb_login: 'api/v3/public/food-order/social-login',
    acquire_merchant: '/web/v1/public/ahmsites/form/acquire-merchant',
    append_page: (pageId: string) => `/v3/internal/page/facebook/${pageId}`,
  },
};
