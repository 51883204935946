import { type AxiosRequestConfig, httpClient } from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export interface FacebookPageRequestBody {
  page_id: string;
  page_access_token: string;
  page_name: string;
}

export interface FacebookPageResponse {
  success: boolean;
  message: string;
}

export const postFacebookPageFn = async (
  body: FacebookPageRequestBody,
  config: AxiosRequestConfig = {}
) => {
  const response = await httpClient.post<FacebookPageResponse>(
    apiRoutes.ahafood.get_page,
    body,
    {
      ...config,
    }
  );

  return response.data;
};
